import React, { lazy } from 'react';
import Loadable from '../components/Loadable';
const Dashboard = Loadable(lazy(() => import('../views/Doctor/Dashboard')))
const AddPatient = Loadable(lazy(() => import('../views/Doctor/AddPatient')))
const PatientList = Loadable(lazy(() => import('../views/Doctor/PatientList')))
const Result = Loadable(lazy(() => import('../views/Doctor/Automatic Evaluation/Result')))
const Screening = Loadable(lazy(() => import('../views/Doctor/Automatic Evaluation/Screening')))
const WaitingForEvalation = Loadable(lazy(() => import('../views/Doctor/Evaluation/WaitingForEvaluation')))
const SharedWithOthers = Loadable(lazy(() => import('../views/Doctor/Evaluation/SharedWithOthers')))
const EvaluatedByMe = Loadable(lazy(() => import('../views/Doctor/Evaluation/EvaluatedByMe')))
const EvaluatedByOthers = Loadable(lazy(() => import('../views/Doctor/Evaluation/EvaluatedByOthers')))
const Profile = Loadable(lazy(() => import('../views/Doctor/Profile')))
const ChangePassword = Loadable(lazy(() => import('../views/Doctor/ChangePassword')))

export const doctorRoutes = [
    { path: "/doctor/home/", component: Dashboard },
    { path: "/doctor/automatic-evaluation/patient-list", component: PatientList },
    { path: "/doctor/amd-screening", component: Screening },
    { path: "/doctor/dr-screening", component: Screening },
    { path: "/doctor/glaucoma-screening", component: Screening },
    { path: "/doctor/amd-prediction", component: Screening },
    { path: "/doctor/screening-result", component: Result },
    { path: "/doctor/add-patient", component: AddPatient },
    { path: "/doctor/evaluation/waiting-for-evaluation", component: WaitingForEvalation },
    { path: "/doctor/evaluation/evaluated-by-me", component: EvaluatedByMe },
    { path: "/doctor/evaluation/shared-with-others", component: SharedWithOthers },
    { path: "/doctor/evaluation/evaluated-by-others", component: EvaluatedByOthers },
    { path: "/doctor/profile", component: Profile },
    { path: "/doctor/change-password", component: ChangePassword },
]
