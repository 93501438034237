import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import logo from '../assets/landing-bg-1.png';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import NotFound from '../views/Notfound404';
import { doctorRoutes } from './DoctorRoutes';
import { authRoutes } from './AuthRoutes';
import { setScreeningListFilter, setScreeningType } from '../store/slices/screeningSlice';
const Routes = () => {
    const user = useSelector((state) => state.authReducer.data)
    // const redux = useSelector((state) => state)
    const location = useLocation();
    const dispatch = useDispatch()
    // console.log(redux, location)

    return (
        <Switch location={location} key={location.pathname}>
            {!user.token && <Redirect from="/doctor" to={"/login"} />}
            <Redirect exact from="/" to={"/login"} />
            {/* auth routes */}
            {authRoutes.map(route => (<Route exact path={route.path} component={route.component} />))}
            {/* doctor routes */}

            {doctorRoutes.map(route => (<Route exact path={route.path} component={route.component} />))}
            {/* prediction redirect ot routes */}
            {user.token && location.pathname === "/amd" && dispatch(setScreeningListFilter("amd")) && <Redirect to={"/doctor/home"} />}
            {user.token && location.pathname === "/dr" && dispatch(setScreeningListFilter("dr")) && <Redirect to={"/doctor/home"} />}
            {user.token && location.pathname === "/glaucoma" && dispatch(setScreeningListFilter("glaucoma")) && <Redirect to={"/doctor/home"} />}
            {user.token && location.pathname === "/amd-prediction" && dispatch(setScreeningListFilter("amd-prediction")) && <Redirect to={"/doctor/home"} />}

            {/* prediction redirect ot routes while not logged in */}
            {!user.token && location.pathname === "/amd" && dispatch(setScreeningListFilter("amd")) && <Redirect to={"/login"} />}
            {!user.token && location.pathname === "/dr" && dispatch(setScreeningListFilter("dr")) && <Redirect to={"/login"} />}
            {!user.token && location.pathname === "/glaucoma" && dispatch(setScreeningListFilter("glaucoma")) && <Redirect to={"/login"} />}
            {!user.token && location.pathname === "/amd-prediction" && dispatch(setScreeningListFilter("amd-prediction")) && <Redirect to={"/login"} />}
            <Route path="*" component={NotFound} />
        </Switch >
    );
};
const styles = theme => ({
    "@global": {
        body: {
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
        },
        html: {
            height: "100%"
        },
        "#componentWithId": {
            height: "100%"
        }
    }
});

export default withStyles(styles)(Routes);
