import React, { lazy } from 'react';
import Loadable from '../components/Loadable';
const Login = Loadable(lazy(() => import('../views/Auth/Login')))
const Register = Loadable(lazy(() => import('../views/Auth/Register')))
const ForgetPass = Loadable(lazy(() => import('../views/Auth/ForgetPass')))
const Instructions = Loadable(lazy(() => import('../views/Instructions')))
const ResetPassword = Loadable(lazy(() => import('../views/Auth/ResetPassword')))
export const authRoutes = [
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/forgot-pass", component: ForgetPass },
    { path: "/instructions", component: Instructions },
    { path: "/reset-password/:user_id/:securityToken", component: ResetPassword },
]
